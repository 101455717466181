<template>
  <div class="wrapper">
    <div class="wrap-head">
      <div class="wrap-bg-box">
        <img class="wrap-bg" src="../../../assets/images/wangshangdai/bg.jpg" alt="">
      </div>
      <div class="wrap-list">
        <div class="wrap-card" style="margin:0">
          <div class="card-head">
            <div class="hd">
              <span class="icon icon-left"></span><span class="card-hd-text">关于云卡</span> <span
              class="icon icon-right"></span></div>
          </div>
          <div class="title">国内首家O2O卡车</div>
          <div class="title">全国维修联盟服务互联网平台</div>
          <div class="card-content">
            云卡，作为国内首家O2O卡车的全国维修联盟服务互联网平台，通过整合线下门店形成连锁联盟，建立起全国范围内的卡车维修服务体系，为物流企业提供一体化服务。全国已经有超过1000家的加盟合作门店，为物流车辆提供全国范围的统一标准、统一流程、统一价格、统一结算的维修保养服务保障。
          </div>
          <div class="card-content">
            云卡携手支付宝（网商银行）为全国的物流车队提供车辆维修专项维修基金（金融授信），为物流车队的车辆维修费用实现全国维修费用挂账结算的服务，为物流车队解决了后顾之忧的，资金使用优先在其他的项目，等待资金回笼后再支付维修费用。
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <img class="footer-img" src="../../../assets/images/wangshangdai/footer.png" alt="">
    </div>
  </div>
</template>
<script>


  export default {
    data: function () {
      return {}
    },

    methods: {}
  }
</script>
<style scoped lang="less">
  .title {
    text-align: center;
    font-size: 1.33rem;
    font-weight: bold;
    color: #1593F8;
  }

  //开始
  .wrapper {
    color: #343434;
    background-color: #F7F7F7;
    font-size: 1.17rem;
    width: 31.25rem;
    height: auto;

    //背景头
    .wrap-head {
      .wrap-bg-box {
        position: relative;
        width: 100%;

        .wrap-bg {
          width: 31.25rem;
          height: 60rem;
        }
      }

      .wrap-list {
        width: 28.75rem;
        height: auto;
        background-color: rgba(255, 255, 255, 0.7);
        border-radius: 0.67rem;
        margin-right: 1.25rem;
        margin-left: 1.25rem;
        position: absolute;
        top: 37.25rem;
        left: 0;

        .wrap-list-item {
          padding: 0 1.33rem;
          margin-top: 2.4rem;
          display: flex;
          justify-content: space-between;

          .item {
            display: flex;
            font-size: 1.25rem;
            font-weight: bold;

            .item-lt {
              .lt-img {
                width: 3.5rem;
                height: 3.5rem;
              }
            }

            .item-rt {
              margin-left: 0.63rem;

              .rt-top-text {
                color: #555555;
              }

              .rt-bottom-text {
                color: #FF6600;
              }
            }
          }
        }
      }
    }

    //底部卡片
    .wrap-card {
      background-image: linear-gradient(180deg, #EBF6FF 0%, #FFFFFF 11%);
      font-size: 1.17rem;
      margin: 0 1.25rem 1.25rem 1.25rem;
      padding-bottom: 2rem;
      border: 0.2rem solid #F6F6F6;
      border-radius: 0.67rem;
      box-shadow: 0 0.5rem 0.83rem 0 rgba(150, 150, 150, 0.10), inset 0 0.08rem 0 0 #FFFFFF;

      .card-head {
        display: flex;
        justify-content: center;
        width: 100%;
        padding:1.5rem 0;
        height: auto;
        background-image: linear-gradient(#EBF6FF, #FFFFFF);

        .hd {
          .icon {
            display: inline-block;
            width: 5rem;
            height: 0.25rem;
            margin-bottom: 0.5rem;
          }

          .icon-right {
            background-image: url('../../../assets/images/wangshangdai/line_right.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }

          .icon-left {
            background-image: url('../../../assets/images/wangshangdai/line_left.png');
            background-repeat: no-repeat;
            background-size: 95% 100%;
          }

          .card-hd-text {
            font-size: 1.5rem;
            font-weight: bold;
          }
        }
      }

      .card-content {
        padding: 0.5rem 1.92rem;

        .text {
          margin-top: 0.3rem;
        }

        #code {
          display: flex;
          align-items: center;
          border-bottom: 0.08rem solid #F0F0F0;

          .input-content {
            width: 40%;
          }

          .code-btn {
            margin-left: 1.5rem;
            color: white;
            width: 7.67rem;
            heigh: 3rem;
            line-height: 3rem;
            text-align: center;
            border-radius: 0.5rem;
            background-color: #FF7B04;
          }
        }

        .input_box {
          display: flex;
          align-items: center;
          padding: 2rem 0;
          border-bottom: 1px solid #F5F5F5;

          .input-content {
            width: 60%;
          }

          .code-btn {
            color: #3479AF;
            width: 7.67rem;
            heigh: 3rem;
            line-height: 3rem;
            text-align: center;
            border-radius: 0.5rem;
            background-color: #D7F3FF;
          }

        }

        .card-step {
          display: flex;
          justify-content: space-between;

          .line {
            margin-top: 0.3rem;

            .icon {
              display: inline-block;
              width: 4rem;
              height: 0.08rem;
              background-color: #D8D8D8;
            }

            .line-icon {
              background-image: url('../../../assets/images/line.png');
              background-repeat: no-repeat;
              background-size: 100% 100%;
            }
          }

          .step {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            .step-top-img {
              width: 3.5rem;
              height: 3.5rem;
            }
          }
        }
      }

    }
    .footer {
      top:76rem;
      left:3rem;
      position: absolute;
      padding: 2rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .footer-img {
        width: 25.17rem;
        height: auto
      }
    }

  }

</style>
